<template>
  <div>
    <div
      class="d-flex justify-space-between card-product-selected"
      :style="type == 'list' ? 'height: 60px;' : ''"
    >
      <section class="d-flex">
        <img
          :src="`${item.medias[0].url}?x-oss-process=image/resize,w_160`"
          :style="`width: ${type == 'list' ? '60' : '100'}px;height: ${
            type == 'list' ? '60' : '100'
          }px`"
        />
        <section class="card-desc">
          <div class="d-flex flex-column">
            <span class="font-s12 truncate">{{ item.name }}</span>
            <span
              class="font-s12 font-w800 primary--text"
              >Rp {{ item.price.toLocaleString("id") }}</span
            >
          </div>

          <div
            v-if="type != 'list'"
            class="d-flex align-center font-s12"
            style="gap: 12px"
          >
            <span class="font-s11">Dibutuhkan</span>
            <div class="card-input">
              <input
                v-model="item.targetQty"
                type="number"
                :placeholder="0"
                @input="checkQty"
              />
              <span>pcs</span>
            </div>
            <div @click="actionDelete">
              <icon-trash-alt
                class="pointer"
                style="color: #c90000"
              ></icon-trash-alt>
            </div>
          </div>
        </section>
      </section>

      <section v-if="type == 'list'" class="pa-2">
        <div class="card-btn primary font-w900" @click="actionAdd">
          <v-icon size="12px" color="white">fas fa-plus</v-icon>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "list",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    "item.targetQty"() {
      this.$emit("actionUpdate");
    },
  },
  methods: {
    checkQty() {
      setTimeout(() => {
        const reg = new RegExp("^[0-9]+$");
        if (parseInt(this.item.targetQty) > this.item.stock) {
          this.item.targetQty = this.item.stock;
        } else if (parseInt(this.item.targetQty) < 1) {
          this.item.targetQty = 1;
        } else if (!reg.test(parseInt(this.item.targetQty))) {
          this.item.targetQty = 1;
        }
      }, 500);
    },
    actionAdd() {
      this.$emit("actionAdd");
    },
    actionDelete() {
      this.$emit("actionDelete");
    },
  },
};
</script>

<style lang="scss" scoped>
.font {
  &-s11 {
    font-size: 11px;
  }
  &-s12 {
    font-size: 12px;
  }
  &-w800 {
    font-weight: 800;
  }
  &-w900 {
    font-weight: 900;
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card {
  &-product-selected {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 6px 0px #0000001f;
  }
  &-desc {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 8px;
  }
  &-input {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 0.8px solid #bbb;
    border-radius: 4px;
    padding: 0px 10px;
    width: 170px;
    height: 30px;
    font-size: 12px;
  }
  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}
</style>
