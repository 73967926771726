<template>
  <div class="d-flex flex-column" style="gap: 6px">
    <section class="d-flex justify-space-between">
      <span class="font-s12 font-w500">Target Donasi Barang</span>
      <span
        class="font-s12 font-w500 secondary--text pointer"
        @click="isDialogProduct = true"
        >&plus; Tambah Barang</span
      >
    </section>

    <section class="box">
      <div
        v-for="(item, idx) in listDonationProductCart"
        :key="item.donationId"
      >
        <card-product
          type="card"
          :item="item"
          @actionDelete="actionDeleteFromDonationProductCart(idx)"
          @actionUpdate="actionUpdateFromDonationProductCart"
        ></card-product>
      </div>
    </section>

    <v-dialog v-model="isDialogProduct" width="640">
      <v-card>
        <section
          class="d-flex flex-column pa-3"
          style="background-color: #ddd; gap: 10px"
        >
          <div class="d-flex justify-space-between align-center">
            <span class="font-s20">Pilih Barang</span>
            <div @click="isDialogProduct = false">
              <icon-close class="pointer"></icon-close>
            </div>
          </div>
          <div class="box-input">
            <input v-model="search" placeholder="Cari produk donasi" />
            <icon-search></icon-search>
          </div>
        </section>

        <section
          class="d-flex align-center pa-3"
          style="background-color: #eee; gap: 10px"
        >
          <v-icon size="12px" color="#C90000"
            >fas fa-exclamation-triangle</v-icon
          >
          <span class="font-s12"
            >Kamu hanya bisa memilih produk yang lokasinya dekat dengan lokasi
            yayasan tempat kamu berada, untuk menghindari keterbatasan jarak
            pengiriman dari penyedia barang dan penerima barang.</span
          >
        </section>

        <section
          v-if="isLoadingSearch"
          class="text-center pa-4"
          style="height: 400px"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </section>
        <section v-else class="box-product">
          <div
            v-for="item in listProducts"
            :key="item.id"
            v-show="!item.isHidden && !item.isDeleted && !item.isBanned"
          >
            <card-product
              type="list"
              :item="item"
              @actionAdd="actionAddToDonationProductCart(item)"
            ></card-product>
          </div>
          <section
            v-if="!isLoadingProduct && listProducts.length <= 0"
            class="text-center"
          >
            <span style="font-size: 12px">Tidak ada product</span>
          </section>
          <div v-if="isLoadingProduct" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else v-intersect="onIntersect" style="height: 100px"></div>
        </section>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="isSuccessAddToCart"
      :timeout="3000"
      color="transparent"
      top
      elevation="0"
      content-class="d-flex justify-center"
    >
      <v-card
        width="184px"
        height="46px"
        class="d-flex justify-center align-center"
        style="border-radius: 8px"
        light
      >
        <v-icon size="14px" color="success" style="margin-right: 8px"
          >fas fa-check-circle</v-icon
        >
        <span style="font-size: 12px; font-weight: 500; color: #4a4a4a"
          >Barang ditambahkan</span
        >
      </v-card>
    </v-snackbar>

    <v-snackbar
      v-model="isErrorGetProduct"
      :timeout="3000"
      color="error"
      outlined
      top
    >
      <span>{{ errorMessage }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cardProduct from "./cardProduct.vue";

const donationItemsAccountId = process.env.VUE_APP_DONATION_ITEMS_ACCOUNT_ID;

export default {
  components: {
    cardProduct,
  },
  props: {
    listDonationProductCart: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      listProducts: [],
      isDialogProduct: false,
      search: "",
      page: 1,
      totalPage: 1,
      isIntersecting: false,
      isLoadingProduct: false,
      isLoadingSearch: false,
      isSuccessAddToCart: false,
      isErrorGetProduct: false,
      errorMessage: "",
    };
  },
  watch: {
    search() {
      if (this.search.length >= 3) {
        this.isLoadingSearch = true;
        this.page = 1;
        this.listProducts = [];
        this.handleGetProductSearch();
      } else if (this.search.length < 1) {
        this.isLoadingSearch = true;
        this.page = 1;
        this.listProducts = [];
        this.handleGetProduct(20);
      }
    },
    isDialogProduct() {
      if (this.isDialogProduct) {
        this.handleGetProduct();
      } else {
        this.listProducts = [];
        this.search = "";
        this.page = 1;
        this.totalPage = 1;
        this.isErrorGetProduct = false;
        this.errorMessage = "";
      }
    },
    isIntersecting() {
      if (
        this.listProducts.length >= 10 &&
        this.isDialogProduct &&
        this.page > 0 &&
        this.page <= this.totalPage
      ) {
        this.handleGetProductNext();
      }
    },
    listDonationProductCart() {
      this.listProducts.forEach((item, idx) => {
        this.listDonationProductCart.forEach((cart) => {
          if (item.id == cart.productId) {
            this.listProducts[idx].isHidden = true;
          }
        });
      });
    },
  },
  destroyed() {
    localStorage.removeItem("donationProductCart");
  },
  methods: {
    ...mapActions({
      getProductListById: "product/getProductListById",
    }),
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    actionAddToDonationProductCart(item) {
      const payload = {
        productId: item.id,
        name: item.name,
        price: item.price,
        medias: item.medias,
        stock: item.stock,
        targetQty: null,
      };
      this.listDonationProductCart.push(payload);
      localStorage.setItem(
        "donationProductCart",
        JSON.stringify(this.listDonationProductCart)
      );
      this.isSuccessAddToCart = true;
    },
    actionDeleteFromDonationProductCart(idx) {
      this.listDonationProductCart.splice(idx, 1);
      localStorage.setItem(
        "donationProductCart",
        JSON.stringify(this.listDonationProductCart)
      );
    },
    actionUpdateFromDonationProductCart() {
      this.$emit("actionUpdateFromDonationProductCart");
    },
    handleGetProduct(size = 20) {
      const payload = {
        id: donationItemsAccountId,
        params: {
          size: size,
          page: 0,
          type: "ORIGINAL",
        },
      };
      this.isLoadingProduct = true;
      return this.getProductListById(payload)
        .then((res) => {
          setTimeout(() => {
            this.isLoadingProduct = false;
            this.isLoadingSearch = false;
          }, 1000);
          const content = res.data.data.content;
          this.page++;
          this.totalPage = res.data.data.totalPages;
          this.listProducts = [];
          content.forEach((item, idx) => {
            this.listProducts.push({
              ...item,
              isHidden: item.stock == 0 ? true : false,
            });
          });
          this.listProducts.forEach((item, idx) => {
            this.listDonationProductCart.forEach((cart) => {
              if (item.id == cart.productId) {
                this.listProducts[idx].isHidden = true;
              }
            });
          });
        })
        .catch((err) => {
          this.isLoadingProduct = false;
          this.isLoadingSearch = false;
          this.isErrorGetProduct = true;
          this.errorMessage = err.response.data
            ? err.response.data
            : "Error Accured";
        });
    },
    handleGetProductNext() {
      const payload = {
        id: donationItemsAccountId,
        params: {
          size: 20,
          page: this.page - 1,
          search: this.search,
          type: "ORIGINAL",
        },
      };
      this.isLoadingProduct = true;
      return this.getProductListById(payload)
        .then((res) => {
          setTimeout(() => {
            this.isLoadingProduct = false;
            this.isLoadingSearch = false;
          }, 1000);
          const content = res.data.data.content;
          this.page++;
          this.totalPage = res.data.data.totalPages;
          content.forEach((item) => {
            this.listProducts.push({
              ...item,
              isHidden: item.stock == 0 ? true : false,
            });
          });
          this.listProducts.forEach((item, idx) => {
            this.listDonationProductCart.forEach((cart) => {
              if (item.id == cart.productId) {
                this.listProducts[idx].isHidden = true;
              }
            });
          });
        })
        .catch((err) => {
          this.isLoadingProduct = false;
          this.isLoadingSearch = false;
          this.isErrorGetProduct = true;
          this.errorMessage = err.response.data
            ? err.response.data
            : "Error Accured";
        });
    },
    handleGetProductSearch() {
      const payload = {
        id: donationItemsAccountId,
        params: {
          size: 20,
          page: this.page - 1,
          search: this.search,
          type: "ORIGINAL",
        },
      };
      this.isLoadingProduct = true;
      return this.getProductListById(payload)
        .then((res) => {
          setTimeout(() => {
            this.isLoadingProduct = false;
            this.isLoadingSearch = false;
          }, 1000);
          const content = res.data.data.content;
          this.page++;
          this.totalPage = res.data.data.totalPages;
          this.listProducts = [];
          content.forEach((item) => {
            this.listProducts.push({
              ...item,
              isHidden: item.stock == 0 ? true : false,
            });
          });
          this.listProducts.forEach((item, idx) => {
            this.listDonationProductCart.forEach((cart) => {
              if (item.id == cart.productId) {
                this.listProducts[idx].isHidden = true;
              }
            });
          });
        })
        .catch((err) => {
          this.isLoadingProduct = false;
          this.isLoadingSearch = false;
          this.isErrorGetProduct = true;
          this.errorMessage = err.response.data
            ? err.response.data
            : "Error Accured";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.font {
  &-s12 {
    font-size: 12px;
  }
  &-s20 {
    font-size: 20px;
  }
  &-w500 {
    font-weight: 500;
  }
}
.box {
  width: 100%;
  height: 600px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  &-input {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 0.8px solid #bbb;
    border-radius: 4px;
    padding: 0px 10px;
    width: 100%;
    height: 30px;
    background-color: white;
    font-size: 12px;
  }
  &-product {
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 400px;
    overflow: auto;
    padding: 12px;
  }
}
</style>
